import styled from 'styled-components';
import { COLOR_FONT, COLOR_MAIN, COLOR_PRIMARY } from '../../../settings';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLOR_MAIN};
    height: 100vh;
    width: 100%;
`

export const Wrapper = styled.div`
    width: 1100px;
    height: 600px;
    display: flex;
    border-radius: 25px;
    position: relative;
    border: 2px solid ${COLOR_FONT};
    background-color: ${COLOR_PRIMARY};
    backdrop-filter: blur(10px);
    
    @media screen and (max-width: 768px) {
        max-width: 500px;
        margin: 20px;
    }
`