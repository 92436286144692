import ImageContainer from "../ImageContainer";
import DescriptionContainer from "../DescriptionContainer";
import Footer from "../Footer";
import { useEffect, useState } from 'react';
import { Container, Wrapper } from "./styles";
import { URL_API } from "../../../settings";

export const NewsLetter = () => {
    const [dadosEmpresa, setDadosEmpresa] = useState<any>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [token, setToken] = useState<any>('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setToken(urlParams.get("token"));

        async function fetchData() {
            try {
                const response = await fetch(URL_API + '?token=' + token);

                if ((response.status === 200) || (response.status === 201)) {
                    const data = await response.json();
                    setDadosEmpresa(data.mensagem[0]);
                }
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
            }

            setLoaded(true);
        }

        if (token)
            fetchData();
    }, [token]);

    return (
        <Container>
            <Wrapper>
                <DescriptionContainer description={dadosEmpresa?.descricao} logo={dadosEmpresa?.master?.logo} loaded={loaded} token={token} />
                <ImageContainer />
                <Footer />
            </Wrapper>
        </Container>
    )
}