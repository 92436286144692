import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`

function getImageDimensions(base64: any) {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            resolved({ width: i.width, height: i.height })
        };

        i.src = base64
    })
}

const LogoEmpresa = (props: any) => {
    const [imgWidth, setImgWidth] = useState<number>(300);
    const [imgHeight, setImgHeight] = useState<number>(150);

    function getScale() {
        var scale = 1;

        if (imgWidth > imgHeight) {
            scale = imgWidth / 300;

            if ((imgHeight / scale) > 150) {
                scale = imgHeight / 150
            }
        } else {
            scale = imgHeight / 150;

            if ((imgWidth / scale) > 300) {
                scale = imgWidth / 300;
            }
        }

        return scale;
    }

    useEffect(() => {
        getImageDimensions(props?.logo).then((img: any) => {
            setImgWidth(img.width);
            setImgHeight(img.height);
        });
    }, [props?.logo]);

    return (
        <Container>
            <img src={props?.logo} alt="" style={{ height: (imgHeight / getScale()), width: (imgWidth / getScale()), filter: 'grayscale(100%) brightness(10000%)' }} />
        </Container>
    )
}

export default LogoEmpresa;