import styled from "styled-components";
import notification from "../../../images/notification.svg";
import { COLOR_SECONDARY } from "../../../settings";

const Container = styled.div`
    max-width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: ${COLOR_SECONDARY};
    clip-path: ellipse(65% 75% at 75% 50%);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    @media screen and (max-width: 768px) {
        max-width: 0px;
    }
`

const Image = styled.div`
    width: auto;
    height: 30em;
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-left: 15%;

    img {
        width: auto;
        height: 70%;
    }

    @media screen and (max-width: 768px) {
        max-width: 0px;

        img {
            width: 0px;
        }
    }
`

const ImageContainer = (props: any) => {
    return (
        <Container>
            <Image>
                <img src={notification} alt="" />
            </Image>
        </Container>
    )
}

export default ImageContainer;