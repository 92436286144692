import styled from "styled-components";
import { COLOR_FONT } from "../../../settings";
import { IMaskInput } from "react-imask";

export const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 85%;
    max-height: 85%;
    height: auto;

    @media screen and (max-width: 768px) {
        max-width: 90%;
        max-height: 90%;
    }
`;

export const Title = styled.h1`
    text-align: center;
    margin: 30px 0;
    color: ${COLOR_FONT};
`

export const Description = styled.h3`
    text-align: center;
    margin: 30px 0;
    color: ${COLOR_FONT};
    font-size: 20px;
`;

export const Observation = styled.p`
    text-align: center;
    color: ${COLOR_FONT};
    font-size: 14px;
`

export const Input = styled(IMaskInput)`
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 17px;
    font-size: 16px;
    color: #000;
    height: 45px;
    padding-right: 3em;
    padding-left: 1.5em;
    text-decoration: none;
    width: 100%;

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-style: italic;
    }

    &:disabled {
        background-color: #d8d2d2;    
    }
`;
