import styled from "styled-components";
import Logo from "../../../images/Logo192.png";
import { COLOR_FONT } from "../../../settings";

const FooterContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    height: 75px;
    color: ${COLOR_FONT};
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
`;

const FooterWrapper = styled.a`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    text-align: center;
    color: ${COLOR_FONT};
    outline: none;
`;

const LogoBitSafira = styled.img`
    filter: brightness(10000%);
    margin-right: 10px;
    width: 30px;
    height: 30px;
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
`;

export const WebSiteRights = styled.small`
    text-align: center;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper href="https://www.bitsafira.com.br" target="_black">
                <LogoBitSafira src={Logo} alt="" />
                <TextContainer>Desenvolvido por BITSAFIRA</TextContainer>
                <WebSiteRights>BITSAFIRA © {new Date().getFullYear()}{'. Todos os direitos reservados.'}</WebSiteRights>
            </FooterWrapper>
        </FooterContainer>
    );
};

export default Footer;
