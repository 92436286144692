import { useEffect, useState } from "react";
import { Container, Title, Description, Wrapper, Button } from "./styles";
import axios from "axios";
import { information } from "../../../commons";
import { useSnackbar } from "notistack";
import { URL_API } from "../../../settings";

const Sessao = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [token, setToken] = useState<any>('');
    const [whatsapp, setWhatsapp] = useState<any>('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setToken(urlParams.get("token"));
        setWhatsapp(urlParams.get("whatsapp"));
    }, []);

    function desconectarClick(e: any) {
        async function desconectar() {
            setDisabled(true);

            try {
                const config = {
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        token: token,
                        whatsapp: whatsapp
                    }
                };

                const response = await axios.delete(URL_API + '/excluir', config);

                if ((response.status === 200) || (response.data.status === 201)) {
                    information(response.data.mensagem, 'success', enqueueSnackbar);
                }
            } catch (error: any) {
                setDisabled(false);
                console.error("Erro ao buscar os dados:", error);
                information(error.response.data.mensagem ? error.response.data.mensagem : 'Erro ao concluir a operação.', 'error', enqueueSnackbar);
            }
        }

        if (token && whatsapp)
            desconectar();
    }

    return (
        <Container>
            <Wrapper>
                <Title>{'Ficamos tristes com essa situação, mais respeitamos sua opinião. :('}</Title>
                <Description>{'Em até 24 horas suas preferências serão ajustadas e você não irá mais receber notificações desta lista de transmissão.'}</Description>
                <Button disabled={disabled} onClick={desconectarClick}>{'Desconectar'}</Button>
            </Wrapper>
        </Container>
    )
}

export default Sessao;