import { Route, Routes } from "react-router-dom"
import Principal from "../pages/Principal"
import Desconectar from "../pages/Desconectar"

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Principal />} />
            <Route path="/desconectar" element={<Desconectar />} />
        </Routes>
    )
}

export default AppRoutes;