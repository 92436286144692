import './App.css';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppRoutes from './routes';

const CloseSnackbarAction = (props: any) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="close"
      color="inherit"
      sx={{ p: 0.5 }}
      onClick={() => closeSnackbar(props.id)}
      style={{ display: 'flex' }}
    >
      <CloseIcon />
    </IconButton>
  );
};

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        action={(key: any) => <CloseSnackbarAction id={key} />}
      >
        <AppRoutes />
      </SnackbarProvider>
    </div >
  );
}

export default App;
