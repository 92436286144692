import styled from "styled-components";
import { COLOR_FONT, COLOR_MAIN, COLOR_PRIMARY } from "../../../settings";

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${COLOR_MAIN};
    padding-top: 50px;
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center; 
`;

export const Title = styled.p`
    color: ${COLOR_FONT};  
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 25px;
    text-align: center;
`
export const Description = styled.p`
    color: ${COLOR_FONT};  
    font-size: 14px;
    padding-bottom: 25px;
    text-align: center;
`

export const Button = styled.button`
    border: none;
    outline: none;
    color: ${COLOR_PRIMARY};
    background-color: ${COLOR_FONT};
    font-size: 16px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    border-radius: 5px;
    width: 110px;
    height: 35px;

    &:hover {
        background-color: #5a88b3;
        color: #fff;
        border: 1px solid #fff;
    }

    &:disabled {
        background-color: #cccbd1;
        color: #403f41;
        border: none;
    }
`