import styled from "styled-components";
import { COLOR_FONT, COLOR_PRIMARY } from "../settings";

export const FormGroup = styled.div`
    display: flex;
    position: relative;
    height: 65px;
`;

export const Button = styled.button`
    position: absolute;
    top: 2px;
    right: 2px;
    border: none;
    outline: none;
    color: ${COLOR_FONT};
    background-color: ${COLOR_PRIMARY};
    font-size: 16px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    padding: 0 10px;
    width: 110px;
    height: 41px;

    &:hover {
        background-color: #5b7bcc;
    }

    &:disabled {
        background-color: #838286;
    }

    &:focus {
        background-color: #1a294e;  
    }
`;