import LogoEmpresa from "../LogoEmpresa";
import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Description, Input, Title, Observation, Container, Wrapper } from "./styles";
import { Button, FormGroup } from "../../FormElemets";
import { information, removeMaskWhatsapp } from "../../../commons";
import { URL_API } from "../../../settings";

const DescriptionContainer = (props: any) => {
    const { enqueueSnackbar } = useSnackbar();

    const [nome, setNome] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [loading, setLoading] = useState(false);
    const [mask, setMask] = useState('(00) 0000-00000');

    function increvaSeClick(e: any) {
        async function salvar() {
            setLoading(true);

            try {
                const request = {
                    token: props.token,
                    nome: nome,
                    whatsapp: whatsapp
                };

                const response = await axios.post(URL_API + '/salvar', request);

                if ((response.status === 200) || (response.data.status === 201)) {
                    information(response.data.mensagem, 'success', enqueueSnackbar);
                    setNome('');
                    setWhatsapp('');
                }
            } catch (error: any) {
                console.error("Erro ao buscar os dados:", error);
                information(error.response.data.mensagem ? error.response.data.mensagem : 'Erro ao concluir a operação.', 'error', enqueueSnackbar);
            }

            setLoading(false);
        }

        if (!nome) {
            information('Necessário informar o seu nome.', 'error', enqueueSnackbar);
            return;
        }

        if (removeMaskWhatsapp(whatsapp).length < 10) {
            information('Necessário informar um número de whatsapp válido.', 'error', enqueueSnackbar);
            return;
        }

        salvar();
    }

    return (
        <Container>
            {props.loaded &&
                (
                    props?.logo ?
                        <Wrapper>
                            <LogoEmpresa logo={'data:image/png;base64,' + props?.logo} />
                            <Description>Faça parte do nosso {props.description} e fique sempre por dentro das nossas novidades.</Description>
                            <FormGroup>
                                <Input
                                    type="text"
                                    placeholder="Digite seu nome"
                                    maxLength={100}
                                    autoComplete="off"
                                    value={nome}
                                    onChange={(e: any) => setNome(e.target.value)}
                                    disabled={loading}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    mask={mask}
                                    placeholder="(34) 99999-9999"
                                    value={whatsapp}
                                    onChange={(e: any) => {
                                        setMask(removeMaskWhatsapp(e.target.value).length === 11 ? '(00) 00000-0000' : '(00) 0000-00000');
                                        setWhatsapp(e.target.value)
                                    }}
                                    disabled={loading}
                                />
                                <Button
                                    onClick={(e: any) => increvaSeClick(e)}
                                    disabled={loading}
                                >
                                    Inscreva-se
                                </Button>
                            </FormGroup>
                            {loading && <Observation>Enviando solicitação, aguarde...</Observation>}
                        </Wrapper> :
                        <Wrapper>
                            <Title>{'OPS! :('}</Title>
                            <Description>
                                {'Houve alguma falha ao carregar os dados da empresa.'} <br />
                                {'Por favor, entre em contato com o administrador da página.'}
                            </Description>
                        </Wrapper>
                )}
        </Container>
    )
}

export default DescriptionContainer;